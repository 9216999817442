import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../../components/layout.pt";
import SEO from "../../../components/seo";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
};

const ContentCreationPT = ({ data }) => {
    const shortify = text => {
        const latinMap = {
            á: "a",
            à: "a",
            ã: "a",
            â: "a",
            ç: "c",
            é: "e",
            ê: "e",
            í: "i",
            ó: "o",
            õ: "o",
            ô: "o",
            ú: "u",
        };
        return text
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\-a-z0-9]/g, x => latinMap[x] || x)
            .replace(/[^\-a-z0-9]/g, "");
    };
    return (
        <Layout>
            <SEO title="Criação de Conteúdo" description="Faça crescer o seu negócio ao impactar e comunicar para um público mais amplo e crie conteúdos para impulsionar as vendas" lang="pt" />

            <div className="brook-breadcaump-area bg_color--1 breadcaump-title-bar" style={{ paddingTop: 150 }}>
                <div className="container-fluid plr--150 plr_md--50 plr_sm--30">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcaump-inner text-left d-inline-block poss_relative">
                                <h2 className="heading">
                                    Faça crescer o seu negócio ao impactar e comunicar para um público mais amplo e crie{" "}
                                    <span class="theme-creative" style={{ fontFamily: "Libre Baskerville" }}>
                                        conteúdos {""}
                                    </span>
                                    para impulsionar as vendas.
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="brook-portfolio-area ptb--100 ptb-md--80 ptb-sm--60 bg_color--1 bk-masonry-wrapper">
                <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="portfolio portfolio_style--1 portfolio-25 grid-width-2 cat--1 cat--4">
                    {data.wbapi.Cottonhat_casestudyList.map(item => (
                        <>
                            <div className="poss_relative">
                                <div className="thumb">
                                    {typeof item.photo !== "undefined" && typeof item.photo.url !== "undefined" && item.photo !== null && item.photo.url !== null ? (
                                        <img src={`${item.photo.url}?w=960&h=960&fit=crop`} alt={item.title.pt_PT ? item.title.pt_PT : item.title} />
                                    ) : (
                                        <img src={`/images/portfolio/grid/metro3-1.jpg`} alt={item.title.pt_PT ? item.title.pt_PT : item.title} />
                                    )}
                                </div>
                                <Link to={`/pt/caso-de-estudo/${item.title.pt_PT ? shortify(item.title.pt_PT) : item.uri}`}>
                                    <div className="port-overlay-info" style={{ backgroundColor: item.overlayColor }}>
                                        <div className="hover-action">
                                            <h3 className="post-overlay-title">
                                                <Link to={`/pt/caso-de-estudo/${item.title.pt_PT ? shortify(item.title.pt_PT) : item.uri}`}>{item.title.pt_PT ? item.title.pt_PT : item.title}</Link>
                                            </h3>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </>
                    ))}
                </Masonry>
            </div>
        </Layout>
    );
};

export default ContentCreationPT;

export const query1 = graphql`
    query {
        wbapi {
            Cottonhat_casestudyList(project: "592d4cf5b549b452a450d829", topic: "cat4") {
                project_id
                title {
                    en_EN
                    pt_PT
                }
                _id
                uri
                normalized_name
                overlayColor
                photo {
                    url
                    edition_options {
                        crop_options {
                            height
                            width
                            x
                            y
                        }
                    }
                }
            }
        }
    }
`;
